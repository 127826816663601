import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Controller } from '@hotwired/stimulus';
import { useMatchMedia } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/hotkeys';
import { MEDIA_QUERIES } from '~/src/global/utils/breakpoints';
import { gsap } from 'gsap';

const CLASSES = {
  open: '-is-open',
  sm: 'modal--sm'
};

const LOADING_TEMPLATE = `
<div class="modal-content min-h-[500px]">
  <div class="y-loader h-full">
    <div class="y-loader__spinner"></div>
  </div>
</div>
`;

export default class extends Controller {
  static targets = [
    'container',
    'content',
    'box',
    'scroll',
    'template',
    'frame',
    'component'
  ];
  static values = {
    isOpen: {
      default: false,
      type: Boolean
    },
    openOnConnect: {
      default: false,
      type: Boolean
    }
  };

  connect() {
    window.modal = this;

    this.isOpenValue = false;

    useMatchMedia(this, {
      mediaQueries: MEDIA_QUERIES
    });

    useHotkeys(this, {
      esc: [this.escKeyHandler]
    });

    if (this.openOnConnectValue === true) {
      this.openTemplate();
    } else {
      if (this.isOpenValue === true) this.open();
    }

    if (this.hasTemplateTarget) {
      this.templateTarget.style.display = 'none';
    }
  }

  isSmall({ name, media, matches, event }) {
    this.openAnimationOptions = {
      opacity: 1,
      y: '0%'
    };

    this.extraOpenAnimationOptions = {
      ease: 'power4.out',
      duration: 0.35
    };

    this.closeAnimationOptions = {
      y: '100%'
    };

    this.extraCloseAnimationOptions = {
      ease: 'power3.in',
      duration: 0.2
    };
  }

  isLarge({ name, media, matches, event }) {
    this.openAnimationOptions = {
      opacity: 1,
      scale: 1,
      y: 0
    };

    this.extraOpenAnimationOptions = {
      ease: 'expo.out',
      duration: 0.4
    };

    this.closeAnimationOptions = {
      opacity: 0,
      scale: 1,
      y: 120
    };

    this.extraCloseAnimationOptions = {
      ease: 'power3.in',
      duration: 0.2
    };
  }

  openTemplate() {
    if (this.hasTemplateTarget) {
      this.contentTarget.scrollTo(0, 0);
      this.frameTarget.innerHTML = this.templateTarget.innerHTML;
    }

    this.isOpenValue = true;
    this.containerTarget.classList.add(CLASSES.open);
    this.boxTarget.style.opacity = '';

    this.dispatch('open');

    gsap.fromTo(
      this.boxTarget,
      {
        ...this.closeAnimationOptions,
        ...this.extraCloseAnimationOptions,
        duration: 0
      },
      {
        ...this.openAnimationOptions,
        ...this.extraOpenAnimationOptions,
        duration: 0,
        onComplete: () => {
          this.isAnimating = false;
        }
      }
    );

    this._setupEventListeners();
  }

  openInline(e) {
    const modalId = e.currentTarget.dataset.modalId;
    if (modalId) {
      this.open(e, modalId);
    } else {
      this.open(e);
    }

    if (this.hasTemplateTarget && e.target.tagName.toLowerCase() !== 'a') {
      this.contentTarget.scrollTo(0, 0);
      if (modalId) {
        this.frameTarget.innerHTML = document.getElementById(modalId).innerHTML;
      } else {
        this.frameTarget.innerHTML = this.templateTarget.innerHTML;
      }
    }
  }

  open(e, modalId = null) {
    if (this.isAnimating) return;
    this.isAnimating = true;
    e.currentTarget.blur();
    this.isOpenValue = true;
    this.containerTarget.classList.add(CLASSES.open);
    this.boxTarget.style.opacity = '';

    if (e.currentTarget.dataset.modalSize == 'sm') {
      this.containerTarget.classList.add(CLASSES.sm);
    }

    this.dispatch('open');

    gsap.fromTo(
      this.boxTarget,
      {
        ...this.closeAnimationOptions,
        ...this.extraCloseAnimationOptions
      },
      {
        ...this.openAnimationOptions,
        ...this.extraOpenAnimationOptions,
        onComplete: () => {
          this.isAnimating = false;
        }
      }
    );

    this._setupEventListeners();
  }

  scrollTargetConnected(target) {
    if (this.isOpenValue === true)
      disableBodyScroll(target, {
        reserveScrollBarGap: true
      });
    const autofocusElement = target.querySelector('[autofocus]');

    if (autofocusElement) autofocusElement.focus();
  }

  escKeyHandler() {
    if (this.isOpenValue === true) this.close();
  }

  close(e) {
    if (this.isAnimating) return;
    this.isAnimating = true;
    if (e) e.preventDefault();
    this.isOpenValue = false;
    this.containerTarget.classList.remove(CLASSES.open);

    this._removeEventListeners();

    gsap.fromTo(
      this.boxTarget,
      {
        ...this.openAnimationOptions,
        ...this.extraOpenAnimationOptions
      },
      {
        ...this.closeAnimationOptions,
        ...this.extraCloseAnimationOptions,
        onComplete: () => {
          this.isAnimating = false;
          this.boxTarget.style.opacity = '0';
          this.dispatch('close');
          clearAllBodyScrollLocks();
          if (this.hasFrameTarget) {
            this.frameTarget.innerHTML = LOADING_TEMPLATE;
          }
        }
      }
    );
  }

  closeOnSubmit(e) {
    if (e.detail.success) this.close();
  }

  _setupEventListeners() {
    this.containerTarget.addEventListener('click', this._handleClickOutside);
  }

  _removeEventListeners() {
    this.containerTarget.removeEventListener('click', this._handleClickOutside);
  }

  _handleClickOutside = (event) => {
    if (
      this.hasComponentTarget &&
      this.componentTarget.dataset.closeOnOutsideClick == 'false'
    ) {
      return;
    }

    if (event.target === this.element.querySelector('.modal__bg')) this.close();
  };
}
